"use client";
import Link from "next/link";
import { Box, Container, Stack, SxProps, Typography } from "@mui/material";
import { Button } from "@/shared/components";
import Image from "next/image";
import { useEffect } from "react";

type Props = {
  message?: string;
  submessage?: string;
  logs?: string;
  sx?: SxProps;
};

function ErrorComponent({ message, submessage, logs, sx }: Props) {
  useEffect(() => {
    console.error(logs);
  }, [logs]);

  return (
    <Container
      sx={{
        padding: {
          xs: "74px 0 50px",
          sm: "48px 0 100px",
          md: "48px 0 100px",
          lg: "48px 0 100px",
          xl: "80px 0 100px",
        },

        ...sx,
      }}
    >
      <Stack alignItems="center">
        <Box
          width="100%"
          maxWidth={{
            xs: "298px",
            md: "490px",
            lg: "715px",
          }}
          mb={4}
        >
          <Image
            src={"/404.png"}
            alt="404"
            width={1200}
            height={1200}
            className="next-image"
          />
        </Box>
        <Typography
          variant="h1"
          component="h1"
          textAlign="center"
          fontSize={{
            xs: "30px",
            md: "40px",
          }}
        >
          {message || "Oops, there's something wrong!"}
        </Typography>

        {submessage && (
          <Typography
            variant="h3"
            component="h3"
            textAlign="center"
            mt={1}
            fontWeight={{
              xs: 500,
            }}
            fontSize={{
              xs: "18px",
              md: "24px",
            }}
          >
            {submessage}
          </Typography>
        )}

        <Stack
          direction="row"
          spacing={{
            xs: "16px",
            md: "24px",
            lg: "32px",
          }}
          marginTop={{ xs: "24px", md: "48px", lg: "56px" }}
        >
          <Box maxWidth={{ xs: "149px", md: "185px", lg: "189px" }}>
            <Link href={"/contact-our-team"} style={{ display: "block" }}>
              <Button
                variant="outlined-primary"
                sx={{
                  backgroundColor: "transparent",
                  border: "none",
                  width: "100%",
                  textDecoration: "underline",
                  fontSize: {
                    xs: "14px",
                    md: "16px",
                  },
                  fontWeight: 400,
                }}
              >
                Contact Support
              </Button>
            </Link>
          </Box>

          <Box maxWidth={{ xs: "149px", md: "185px", lg: "189px" }}>
            <Link href={"/"} style={{ display: "block" }}>
              <Button
                variant="filled"
                sx={{
                  width: "100%",
                }}
              >
                Go to Homepage
              </Button>
            </Link>
          </Box>
        </Stack>
      </Stack>
    </Container>
  );
}

export default ErrorComponent;
