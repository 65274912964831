"use client";
import { ToastContainer } from "react-toastify";
import Icon from "./icon.component";
import React from "react";

const ClientSideToastComponent = () => (
  <ToastContainer
    position="bottom-left"
    theme="colored"
    hideProgressBar
    closeButton={({ closeToast }) => (
      <Icon
        icon="close"
        onClick={(e) =>
          closeToast(e as unknown as React.MouseEvent<HTMLElement>)
        }
        width={16}
        height={16}
        color="#303133"
        sx={{
          my: "auto",
          cursor: "pointer",
        }}
      />
    )}
  />
);

export default ClientSideToastComponent;
